import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { fetchAuthors } from '../services/api';
import Avatar from '../images/whatsapp.png';

const Authors = () => {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getAuthors = async () => {
      try {
        const data = await fetchAuthors();
        setAuthors(data);
      } catch (error) {
        setError('Error fetching authors');
      } finally {
        setLoading(false);
      }
    };

    getAuthors();
  }, []);

  if (loading) {
    return <h2 className='center'>Loading authors...</h2>;
  }

  if (error) {
    return <h2 className='center'>{error}</h2>;
  }

  return (
    <section className="authors">
      {authors.length > 0 ? (
        <div className="container authors__container">
          {authors.map(({ uuid, name, lastName, postCount }) => (
            <Link key={uuid} to={`/posts/users/${uuid}`} className='author'>
              <div className="author__avatar">
                <img src={Avatar} alt={`Image of ${name} ${lastName}`} />
              </div>
              <div className="author__info">
                <h4>{name} {lastName}</h4>
                <p>Posts: {postCount}</p>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <h2 className='center'>No users/authors found.</h2>
      )}
    </section>
  );
};

export default Authors;
