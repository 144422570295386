import axios from "axios";
import { supabase } from "../supabaseClient";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getToken = () => localStorage.getItem("token");

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const fetchUserRole = async (token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await api.get(`/users/me`, config);
    const userProfile = response.data;
    if (userProfile) {
      return userProfile.roles.includes("ADMIN_PUY") ? "admin" : "user";
    }

    return "user";
  } catch (error) {
    console.log("Error fetching user role:", error);
    setTimeout(500000);
    throw error;
  }
};

export const fetchPosts = async () => {
  try {
    const response = await api.get("/bloglist");
    return response.data;
  } catch (error) {
    console.error("Error fetching posts:", error);
    throw error;
  }
};

export const fetchPostById = async (id) => {
  try {
    const response = await api.get(`/bloglist/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching post with id ${id}:`, error);
    throw error;
  }
};

export const createPost = async (postData, token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await api.post("/blog", postData, config);
    return response.data;
  } catch (error) {
    console.error("Error creating post:", error);
    throw error;
  }
};

export const updatePost = async (id, postData, token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await api.patch(`/blog/${id}`, postData, config);
    return response.data;
  } catch (error) {
    console.error(`Error updating post with id ${id}:`, error);
    throw error;
  }
};

export const deletePost = async (id, token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await api.delete(`/blog/${id}`, config);
    return response.data;
  } catch (error) {
    console.error(`Error deleting post with id ${id}:`, error);
    throw error;
  }
};

export const fetchPostsByAuthor = async (authorId) => {
  try {
    const response = await api.get(`/bloglist/author/${authorId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching posts by author with id ${authorId}:`, error);
    throw error;
  }
};

export const fetchPostsByCategory = async (category) => {
  try {
    const response = await api.get(`/bloglist/category/${category}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching posts for category ${category}:`, error);
    throw error;
  }
};

export const fetchAuthors = async () => {
  try {
    const response = await api.get("/bloglist/authors");
    return response.data;
  } catch (error) {
    console.error("Error fetching authors:", error);
    throw error;
  }
};

export const fetchUserDetails = async () => {
  try {
    const response = await api.get("/auth/user");
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

export const updateUserDetails = async (userData) => {
  try {
    const response = await api.put("/auth/user", userData);
    return response.data;
  } catch (error) {
    console.error("Error updating user details:", error);
    throw error;
  }
};

export const signUp = async (userData) => {
  try {
    const { error } = await supabase.auth.signUp({
      email: userData.email,
      password: userData.password,
      phone: userData.phone,
      options: {
        data: {
          phone: userData.phone,
          first_name: userData.name,
          last_name: userData.lastName,
          country: userData.country,
        },
      },
    });
    if (error) {
      throw error;
    }
  } catch (error) {
    console.error("Error signing up:", error);
    throw error;
  }
};

export const signIn = async (email, password) => {
  try {
    const { data } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_SUPABASE_TOKEN) ?? '');
    const userRole = await fetchUserRole(token.access_token);
    localStorage.setItem("userRole", userRole);
    return data;
  } catch (error) {
    console.error("Error signing in:", error);
    throw error;
  }
};

export const signOut = () => {
  localStorage.removeItem("token");
  window.location.href = "/login";
};
