import React from 'react';
import { Link } from 'react-router-dom'
import Avatar from '../images/icon.png'

const PostAuthor = () => {
  return (
    <div 
      //to={`/posts/users/page`}
      className='post__author'>
      <div className="post__author-avatar">
        <img src={Avatar} alt="" />
      </div>
      <div className="post__author-details">
        <h5>By: Puy</h5>
      </div>
    </div>
  )
}

export default PostAuthor;