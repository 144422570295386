import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { fetchUserDetails, updateUserDetails } from '../services/api';

import Avatar from '../images/whatsapp.png'
import { FaEdit, FaCheck } from "react-icons/fa";

const UserProfile = () => {
  const [avatar, setAvatar] = useState(Avatar)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await fetchUserDetails();
        setName(userData.user_metadata.name);
        setEmail(userData.email);
        setAvatar(userData.avatar || Avatar);
      } catch (error) {
        setErrorMessage('Failed to load user data');
      }
    };
    loadUserData();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const userData = {
        name,
        email,
        currentPassword,
        newPassword,
        avatar,
      };
      await updateUserDetails(userData);
      setSuccessMessage('User details updated successfully');
    } catch (error) {
      setErrorMessage('Failed to update user details');
    }
  };

  return (
    <section className="profile">
      <div className="container profile__container">
        <Link to={`/myposts/page`} className='btn'>My posts</Link>

        <div className="profile__details">
          <div className="avatar__wrapper">
            <div className="profile__avatar">
              <img src={avatar} alt="" />
            </div>
            {/* Form to update avatar */}
            <form className="avatar__form">
              <input type="file" name="avatar" id="avatar" onChange={e => setAvatar(e.target.files[0])}
                accept="png, jpg, jpeg" />
              <label htmlFor="avatar"><FaEdit /></label>
            </form>
            <button className="profile__avatar-btn"><FaCheck /></button>
          </div>

          <h1>{name}</h1>

          {/* form to update user details */}
          <form onSubmit={handleUpdate} className="form profile__form">
            {errorMessage && <p className="form__error-message">{errorMessage}</p>}
            {successMessage && <p className="form__success-message">{successMessage}</p>}
            <input
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Current password"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm new password"
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
            />
            <button type="submit" className="btn primary">Update details</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default UserProfile;