import React from "react";

import Layout from "./components/Layout";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import PostDetail from "./pages/PostDetail";
import Register from "./pages/Register";
import Login from "./pages/Login";
import UserProfile from "./pages/UserProfile";
import Authors from "./pages/Authors";
import CreatePost from "./pages/CreatePost";
import EditPost from "./pages/EditPost";
import DeletePost from "./pages/DeletePost";
import CategoryPosts from "./pages/CategoryPosts";
import AuthorPosts from "./pages/AuthorPosts";
import Dashboard from "./pages/Dashboard";
import Logout from "./pages/Logout";
import ProtectedRoute from "./components/ProtectedRoute";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <Home /> },
        { path: "posts/:id", element: <PostDetail /> },
        { path: "register", element: <Register /> },
        { path: "login", element: <Login /> },
        {
          path: "profile/:id",
          element: <ProtectedRoute />,
          children: [{ path: "", element: <UserProfile /> }],
        },
        { path: "authors", element: <Authors /> },
        {
          path: "create",
          element: <ProtectedRoute />,
          children: [{ path: "", element: <CreatePost /> }],
        },
        { path: "posts/categories/:category", element: <CategoryPosts /> },
        { path: "posts/users/:id", element: <AuthorPosts /> },
        {
          path: "myposts/:id",
          element: <ProtectedRoute />,
          children: [{ path: "", element: <Dashboard /> }],
        },
        {
          path: "posts/:id/edit",
          element: <ProtectedRoute />,
          children: [{ path: "", element: <EditPost /> }],
        },
        {
          path: "posts/:id/delete",
          element: <ProtectedRoute />,
          children: [{ path: "", element: <DeletePost /> }],
        },
        {
          path: "logout",
          element: <ProtectedRoute />,
          children: [{ path: "", element: <Logout /> }],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
