import React, { useEffect, useState } from "react";
import PostAuthor from "../components/PostAuthor";
import DOMPurify from "dompurify";

import { Link, useParams } from "react-router-dom";
import Thumbnail from "../images/logo.png";
import { fetchPostById } from "../services/api";
import { useAuth } from "../services/useAuth";

const PostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const getPostDetails = async () => {
      try {
        const postData = await fetchPostById(id);
        setPost(postData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching post details:", error);
        setError("Error fetching post details");
        setLoading(false);
      }
    };

    getPostDetails();
  }, [id]);

  if (loading) {
    return <div className="center">Loading...</div>;
  }

  if (error) {
    return <div className="center">{error}</div>;
  }


  return (
    <section className="post-detail">
      <div className="container post-detail__container">
        <div className="post-detail__header">
          <PostAuthor authorId={post.ownerUuid} />
          {user && localStorage.getItem("userRole") === "admin" && (
            <div className="post-detail__buttons">
              <Link to={`/posts/${id}/edit`} className="btn sm primary">
                Edit
              </Link>
              <Link to={`/posts/${id}/delete`} className="btn sm danger">
                Delete
              </Link>
            </div>
          )}
        </div>
        <h1>{post.title}</h1>
        <div className="post-detail__thumbnail">
          <img src={post.thumbnail || Thumbnail} alt={post.title} />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}
        />
      </div>
    </section>
  );
};

export default PostDetail;
