import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { fetchPosts } from '../services/api';

const Dashboard = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const data = await fetchPosts();
        setPosts(data);
      } catch (error) {
        setError('Error fetching posts');
      } finally {
        setLoading(false);
      }
    };

    getPosts();
  }, []);

  if (loading) {
    return <h2 className='center'>Loading posts...</h2>;
  }

  if (error) {
    return <h2 className='center'>{error}</h2>;
  }

  return (
    <section className="dashboard">
      {
        posts.length ? (
          <div className="container dashboard__container">
            {posts.map(post => (
              <article key={post.id} className='dashboard__post'>
                <div className="dashboard__post-info">
                  <div className="dashboard__post-thumbnail">
                    <img src={post.thumbnail} alt="" />
                  </div>
                  <h5>{post.title}</h5>
                </div>
                <div className="dashboard__post-actions">
                  <Link to={`/posts/${post.id}`} className='btn sm'>View</Link>
                  <Link to={`/posts/${post.id}/edit`} className='btn sm primary'>Edit</Link>
                  <Link to={`/posts/${post.id}/delete`} className='btn sm danger'>Delete</Link>
                </div>
              </article>
            ))}
          </div>
        ) : (
          <h2 className='center'>You have no posts yet.</h2>
        )
      }
    </section>
  );
};

export default Dashboard;
