import React, { useState } from "react";
import { deletePost } from "../services/api";

import { useParams, useNavigate } from "react-router-dom";

const DeletePost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleDelete = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this post? This action cannot be undone.");
    if (!confirmed) return;

    try {
      const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_SUPABASE_TOKEN) ?? '');
      await deletePost(id, token.access_token);
      setSuccess("Post deleted successfully");
      setError(null);
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      setError("Error deleting post");
      setSuccess(null);
    }
  };

  return (
    <section className="delete-post">
      <div className="container">
        {error && <p className="form__error-message" style={{ marginBottom: '20px' }}>{error}</p>}
        {success && <p className="form__success-message" style={{ marginBottom: '20px' }}>{success}</p>}
        <button onClick={handleDelete} className="btn danger">
          Delete Post
        </button>
      </div>
    </section>
  );
};

export default DeletePost;
