import React, { useState, useEffect } from 'react';
import PostItem from '../components/PostItem';

import { fetchPostsByCategory } from '../services/api';
import { useParams } from 'react-router-dom';

const CategoryPosts = () => {
  const { category } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const data = await fetchPostsByCategory(category);
        setPosts(data);
      } catch (error) {
        setError('Error fetching posts');
      } finally {
        setLoading(false);
      }
    };

    getPosts();
  }, [category]);

  if (loading) {
    return <h2 className='center'>Loading posts...</h2>;
  }

  if (error) {
    return <h2 className='center'>{error}</h2>;
  }

  return (
    <section className="posts">
      {posts.length > 0 ? (
        <div className="container posts__container">
          {posts.map(({ uuid, thumbnail, category, title, description, ownerUuid }) => (
            <PostItem
              key={uuid}
              postID={uuid}
              thumbnail={thumbnail}
              category={category}
              title={title}
              description={description}
              authorID={ownerUuid}
            />
          ))}
        </div>
      ) : (
        <h2 className='center'>No posts found</h2>
      )}
    </section>
  );
};

export default CategoryPosts;