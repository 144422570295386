import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { fetchPostById, updatePost } from "../services/api";
import { useParams } from "react-router-dom";
import Logo from '../images/logo.png'

const EditPost = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("Uncategorized");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [thumbnail, setThumbnail] = useState(Logo);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPost = async () => {
      try {
        const data = await fetchPostById(id);
        setTitle(data.title);
        setCategory(data.category);
        setDescription(data.description);
        setContent(data.content);
        setThumbnail(data.thumbnail || Logo);
        setLoading(false);
      } catch (error) {
        setError("Error loading post");
        setLoading(false);
      }
    };

    loadPost();
  }, [id]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const POST_CATEGORIES = ["News", "Uncategorized"];

  const handleThumbnailChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {};
      formData.title = title;
      formData.category = category;
      formData.description = description;
      formData.content = content;
      formData.thumbnail = thumbnail;
      const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_SUPABASE_TOKEN) ?? '');
      await updatePost(
        id,
        formData,
        token.access_token
      );
      setSuccess("Post updated successfully");
      setError(null);
    } catch (error) {
      setError("Error updating post");
      setSuccess(null);
    }
  };

  if (loading) {
    return <h2 className="center">Loading post...</h2>;
  }

  return (
    <section className="edit-post">
      <div className="container">
        <h2>Edit Post</h2>
        <form className="form edit-post__form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            autoFocus
          />
          <select
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            style={{ marginBottom: '10px' }}
          >
            {POST_CATEGORIES.map((cat) => (
              <option key={cat}>{cat}</option>
            ))}
          </select>
          <h4>Description</h4>
          <div style={{ marginBottom: '10px' }}>
            <ReactQuill
              modules={modules}
              formats={formats}
              value={description}
              onChange={setDescription}
              placeholder='Enter a brief description for your post'
            />
          </div>
          <h4>Content</h4>
          <div style={{ marginBottom: '10px' }}>
            <ReactQuill
              modules={modules}
              formats={formats}
              value={content}
              onChange={setContent}
              placeholder='Enter the main content of your post'
            />
          </div>
          {/* <input
            type="file"
            onChange={handleThumbnailChange}
            accept="image/png, image/jpeg, image/jpg"
          /> */}
          <button type="submit" className="btn primary">
            Update
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </div>
    </section>
  );
};

export default EditPost;
