import React from 'react';
import { Link } from "react-router-dom"
import PostAuthor from './PostAuthor'
import DOMPurify from 'dompurify';

const PostItem = ({ postID, thumbnail, category, title, description, authorID }) => {
  const postTitle = title.length > 50 ? title.substr(0, 50) + '...' : title;
  const shortDescription = description.length > 200 ? description.substr(0, 200) + '...' : description;

  return (
    <article className="post">
      <div className="post__thumbnail">
        <img src={thumbnail} alt={title} />
      </div>
      <div className="post__content">
        <Link to={`/posts/${postID}`}>
          <h3>{postTitle}</h3>
        </Link>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(shortDescription) }} />
        <div className="post__footer">
          <PostAuthor authorId={authorID} />
          <div 
            //to={`/posts/categories/${category}`}
            className='btn category'>{category}</div>
        </div>
      </div>
    </article>
  );
};

export default PostItem;
